.subject-list {
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin: 50px -20px;
  .subject {
    opacity: 0.73;
    font-size: 24px;
    line-height: 60px;
    font-weight: 600;
    color: #ffffff;
    padding: 0 20px;
    cursor: pointer;

    &:hover,
    &:focus,
    &::selection {
      opacity: 1;
    }
    &.active {
      font-size: 30px;
      font-weight: 700;
      opacity: 1;
    }
  }
}
