.category-learner {
  .label {
    color: #e79a6c;
  }
  &:hover,
  &.selected,
  &:focus,
  &::selection {
    opacity: 0.95;
    box-shadow: 6px 6px 17px 4px #00a6b5;
    background-image: linear-gradient(133deg, #f8b74e 2%, #f29232 63%, #e77619 91%);
    .label {
      color: #fff;
    }
  }
}
.category-play {
  .label {
    color: #c5d83e;
  }
  &:hover,
  &.selected,
  &:focus,
  &::selection {
    opacity: 0.95;
    box-shadow: 6px 6px 17px 4px #00a6b5;
    background-image: linear-gradient(134deg, #ebff5d -3%, #bbd646 36%, #8da100 90%);
  }
}
.category-health {
  .label {
    color: #87beba;
  }
  &:hover,
  &.selected,
  &:focus,
  &::selection {
    box-shadow: 6px 6px 17px 4px #007b86;
    background-image: linear-gradient(135deg, #cbfffc, #7fc9c3 60%, #62b4ae 86%);
  }
}
.category-teach {
  .label {
    color: #ffca01;
  }
  &:hover,
  &.selected,
  &:focus,
  &::selection {
    box-shadow: 6px 6px 17px 4px #00a6b6;
    background-image: linear-gradient(135deg, #fff386 12%, #f0d13e 63%, #e3b400);
  }
}
.category-life {
  .label {
    color: #ca3366;
  }
  &:hover,
  &.selected,
  &:focus,
  &::selection {
    box-shadow: 6px 6px 17px 4px #00a6b6;
    background-image: linear-gradient(134deg, #ff90c3 7%, #d13f73 59%, #a90d41 102%);
  }
}
.category-exam {
  .label {
    color: #420085;
  }
  &:hover,
  &.selected,
  &:focus,
  &::selection {
    box-shadow: 6px 6px 17px 4px #007b86;
    background-image: linear-gradient(137deg, #af2afa 9%, #440087 63%, #27004f 88%);
  }
}
.category-matric {
  .label {
    color: #13a54d;
  }
  &:hover,
  &.selected,
  &:focus,
  &::selection {
    box-shadow: 6px 6px 17px 4px #007b86;
    background-image: linear-gradient(136deg, #1ce287, #14a750 45%, #00772f 90%);
  }
}
.category-holiday-festival {
  .label {
    color: #e79a6c;
  }
  &:hover,
  &.selected,
  &:focus,
  &::selection {
    box-shadow: 6px 6px 17px 4px #00a6b6;
    background-image: linear-gradient(139deg, #ffcea3 -2%, #dc8a5a 94%);
  }
}
.category-qa-questions {
  .label {
    color: #ef8126;
  }
  &:hover,
  &.selected,
  &:focus,
  &::selection {
    opacity: 0.95;
    box-shadow: 6px 6px 17px 4px #00a6b5;
    background-image: linear-gradient(133deg, #f8b74e 2%, #f29232 63%, #e77619 91%);
  }
}
.category-exam-questions {
  .label {
    color: #c5d83e;
  }
  &:hover,
  &.selected,
  &:focus,
  &::selection {
    opacity: 0.95;
    box-shadow: 6px 6px 17px 4px #007b86;
    background-image: linear-gradient(134deg, #ebff5d -3%, #bbd646 36%, #8da100 90%);
  }
}

.category-qa-live {
  .label {
    color: #ef8126;
  }
  &:hover,
  &.selected,
  &:focus,
  &::selection {
    opacity: 0.95;
    box-shadow: 6px 6px 17px 4px #007b86;
    background-image: linear-gradient(134deg, #ecdaca -3%, #f5b077 36%, #f07109 90%);
  }
}

.category-big-school {
  justify-content: center !important;
  img.hover {
    display: none;
  }
  .label {
    color: #c5d83e;
  }
  &:hover,
  &.selected,
  &:focus,
  &::selection {
    opacity: 0.95;
    box-shadow: 6px 6px 17px 4px #007b86;
    background-image: linear-gradient(134deg, #ebff5d -3%, #bbd646 36%, #8da100 90%);
  }
}

.category-effect {
  .label {
    color: #fff !important;
  }
  g,
  path {
    color: #fff;
    fill: #fff;
  }
}

.category-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  li {
    padding: 0 15px;
    width: 25%;
  }
  .category {
    height: 300px;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    margin-bottom: 80px;
    &:hover,
    &.selected,
    &:focus {
      @extend .category-effect;
    }
    .label {
      font-size: 34px;
      font-weight: bold;
      margin: 50px 0 40px;
    }
  }
}

.confirmation-popup {
  padding: 40px;
  text-align: center;
  border-radius: 10px;
  .heading {
    font-size: 30px;
    color: #008d9d;
  }
}
