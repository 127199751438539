.video-list {
  margin: -15px;
  display: flex;
  flex-wrap: wrap;
  li {
    padding: 15px;
    width: 25%;
  }
  .video {
    background: gray;
    height: 300px;
    cursor: pointer;
    position: relative;
    display: block;
  }
  .video-mask {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    box-shadow: 4px 4px 9px 5px #00929a;
    background-color: rgba($color: #000000, $alpha: 0.2);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 10px 10px 10px;
    .videoName {
      font-size: 24px;
      font-weight: 800;
      letter-spacing: -0.07px;
      color: #ffffff;
      position: absolute;
      bottom: 0px;
      left: 1rem;
    }
    .videoDescription {
      color: #fff;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
    }
    &:hover,
    &:focus {
      .videoDescription {
        line-height: 2.5ex;
        height: 7.5ex;
        white-space: normal;
      }
    }
  }
}
